// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useController } from 'react-hook-form';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';
import { s3 } from 'utilities/api';
import { useInitiativeDataStore } from 'utilities/store';

// Icons
import { FiImage, FiFileText, FiVideo } from 'react-icons/fi';

// Components
import SignedUrlMedia from 'components/signedUrlMedia';
import Button from 'components/button';
import Spinner from 'components/spinner';

const FileComponent = ({
    controller,
    defaultValue,
    name,
    object,
    folder,
    theme,
    accept,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // STORES
    // ///////////////////

    const { utilities, CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { number } = useFormat();
    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
    });

    // ///////////////////
    // STATE
    // ///////////////////

    const [attachLoading, setAttachLoading] = useState(false);
    const [attachProgress, setAttachProgress] = useState(null);
    const [fileTypeGroup, setFileTypeGroup] = useState(null);
    const [fileName, setFileName] = useState(null);

    // ///////////////////
    // METHODS
    // ///////////////////

    async function uploadFile(event) {
        if (event.target.files.length > 0) {
            // Start loading
            setAttachLoading(true);

            // Get file
            const file = event.target.files[0];

            // Set the file type group
            const fileTypeGroup = getFileTypeGroup(file);
            setFileTypeGroup(fileTypeGroup);

            // Set the file name
            setFileName(file.name);

            if (file.size > 199999999) {
                alert('File size should be under 200 mb');
            } else {
                try {
                    // Get signed url
                    const signedUrl = await s3.getSignedUrlForFile({
                        fileName: file.name,
                        folder,
                        initiativeId: utilities.initiative.get().Id,
                    });

                    // Upload
                    await s3.uploadFileWithSignedUrl(
                        file,
                        signedUrl,
                        setAttachProgress
                    );
                    onChange({
                        url:
                            decodeURIComponent(signedUrl.split('?')[0]) ?? null,
                        type: fileTypeGroup,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            setAttachLoading(false);
            setAttachProgress(null);
        }
    }

    function getFileTypeGroup(file) {
        // Get the file type group by extracting the file extension from the file name and compare it with acceptAll
        const fileType = `.${file.name.split('.').pop()}`;
        if (accept.picture.includes(fileType)) {
            return CONSTANTS.CONTENTS.PICTURE;
        }
        if (accept.video.includes(fileType)) {
            return CONSTANTS.CONTENTS.VIDEO;
        }
        if (accept.document.includes(fileType)) {
            return CONSTANTS.CONTENTS.DOCUMENT;
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const acceptAll = [...accept.picture, ...accept.document, ...accept.video];

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <label className="flex flex-col" htmlFor={name}>
            <input
                ref={ref}
                id={name}
                type="file"
                accept={acceptAll}
                onChange={uploadFile}
                defaultValue={''}
                className={cc([
                    'input-defaults hidden',
                    {
                        'input-defaults-blue': isBlue,
                        'input-defaults-blue-alt': isBlueAlt,
                        'input-defaults-teal': isTeal,
                        'input-defaults-error': error,
                        'mt-16': inputLabel,
                    },
                ])}
            />
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-blue-alt': isBlueAlt,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-blue-alt': isBlueAlt,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}

            <div className="flex items-center mt-8 space-x-16">
                <Button
                    variant="secondary"
                    theme={theme}
                    disabled={attachLoading}
                    action={'fake'}>
                    {label('ButtonUpload')}
                </Button>

                {attachLoading && (
                    <>
                        <Spinner size="small" theme={theme} />
                        {attachProgress && (
                            <span className="mt-4 t-caption-bold">
                                {number(attachProgress)} %
                            </span>
                        )}
                    </>
                )}
            </div>

            {(value || defaultValue) && (
                <>
                    <div
                        className={cc([
                            'flex flex-col w-full p-16 rounded-8 t-caption-bold space-y-16 mt-24',
                            {
                                'bg-blue-10': isBlue,
                                'bg-blue-10': isBlueAlt,
                                'bg-teal-10': isTeal,
                            },
                        ])}>
                        <div className="flex justify-center space-x-8">
                            {fileTypeGroup === CONSTANTS.CONTENTS.PICTURE && (
                                <FiImage className="-mt-2 text-20" />
                            )}
                            {fileTypeGroup === CONSTANTS.CONTENTS.VIDEO && (
                                <FiVideo className="-mt-2 text-20" />
                            )}
                            {fileTypeGroup === CONSTANTS.CONTENTS.DOCUMENT && (
                                <FiFileText className="flex-shrink-0 -mt-2 text-20" />
                            )}
                            <span>{fileName}</span>
                        </div>
                        {fileTypeGroup === CONSTANTS.CONTENTS.PICTURE && (
                            <SignedUrlMedia
                                url={value?.url || defaultValue?.url}
                                type="image"
                                className="rounded-8"
                            />
                        )}

                        {fileTypeGroup === CONSTANTS.CONTENTS.VIDEO && (
                            <SignedUrlMedia
                                url={value?.url || defaultValue?.url}
                                type="video"
                                className="rounded-8"
                            />
                        )}
                    </div>
                </>
            )}

            {error && (
                <div className="flex mt-6 -mb-16 input-utility-text">
                    {error && (
                        <div className="input-utility-text-error">
                            {errorLabel}
                        </div>
                    )}
                </div>
            )}
        </label>
    );
};

FileComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.string,
    name: t.string.isRequired,
    object: t.object.isRequired,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
    accept: t.shape({
        video: t.arrayOf(t.string),
        picture: t.arrayOf(t.string),
        document: t.arrayOf(t.string),
    }),
};

FileComponent.defaultProps = {
    theme: 'teal',
};

export default FileComponent;
